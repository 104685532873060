.certifications {
  &__content {
    padding: 30px;
    width: 100%;

    &__filter {
      display: inline-block;
      margin: 0 auto;
      border-radius: 25px;
      border: 1px dashed var(--yellow-theme-main-color);

      li {
        font-size: 16px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        color: var(--yellow-theme-main-color);
        text-transform: uppercase;
        padding: 12px 20px;
        overflow: hidden;
        transition: all 0.5s ease;

        &.active {
          background: var(--yellow-theme-main-color);
          color: var(--yellow-theme-sub-text-color);
          font-weight: 600;
          border-radius: 25px;
        }
      }
    }

    &__cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Flexible grid for desktop */
      grid-auto-rows: auto;
      gap: 7rem;
      margin-top: 20px;

      &__item {
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        height: auto;
        width: auto;

        &:hover .overlay {
          opacity: 1;
          border-radius: 5px;
        }

        &__img-wrapper {
          height: 100%;

          a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              border-radius: 5px;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
              transition: transform 0.3s ease;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }

        .overlay {
          border-radius: 5px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          background-color: var(--yellow-theme-main-color);
          display: flex;
          align-items: center;
          justify-content: center;

          div {
            text-align: center;

            p {
              color: #000000;
              margin: 0px;
              font-size: 4rem;
              position: relative;
              overflow: hidden;
              white-space: nowrap;
              animation: typingEffect 1s steps(30, end);
            }

            button {
              cursor: pointer;
              margin-top: 16px;
              padding: 10px 70px;
              font-size: 2rem;
              background: transparent;
              color: #000;
              border: 1.5px solid #000;
              border-radius: 4px;
              font-weight: 500;
            }
          }
        }
      }

      @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr; /* Change to 1 grid for mobile view */

        &__item {
          width: 100%; /* Full width for mobile view */
        }
      }
    }
  }
}

/* Media queries for adjusting font size in smaller screens */
@media only screen and (max-width: 480px) {
  .certifications__content__filter>li {
    font-size: 11px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .certifications__content__filter>li {
    font-size: 14px;
  }

  .certifications__content__cards {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    margin-top: 10px;

    &__item {
      height: 200px;
      width: 100%;
    }
  }
}

@keyframes typingEffect {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
