.contact {
    &__content {
        padding: 20px;
        display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // gap: 40px;

        // border: 2px solid rgb(212, 0, 255);

        &__header-text {
            color: var(--yellow-theme-sub-text-color);
            display: inline-block;
            font-size: 22px;
            font-weight: 600;
            line-height: 1em;
            letter-spacing: 0.5px;
            position: relative;
            text-transform: uppercase;
            padding: 5px 10px;
            border-bottom: 2px solid var(--yellow-theme-main-color);
        }

        &__header-text::before,
        &__header-text::after {
            position: absolute;
            content: "";
            width: 2px;
            height: 5px;
            background-color: var(--yellow-theme-main-color);
            bottom: -1px;
        }

        &__header-text::before {
            left: 0;
        }

        &__header-text::after {
            right: 0;
        }

        &__form {
            margin-top: 30px;
            // border: 2px solid rgb(0, 255, 234);
            width: 50%;

            &__controlswrapper {
                margin-bottom: 30px;


                div {
                    position: relative;
                    margin-bottom: 20px;

                    .inputName,
                    .inputEmail,
                    .inputDescription {
                        width: 100%;
                        border: 2px solid var(--yellow-theme-main-color);
                        padding: 16px 12px;
                        transition: all 0.5s ease;
                        position: relative;
                        background-color: transparent;
                        color: var(--yellow-theme-sub-text-color);
                    }

                    .nameLabel,
                    .emailLabel,
                    .descriptionLabel {
                        font-weight: 500;
                        position: absolute;
                        top: 7px;
                        left: 12px;
                        transition: all 0.2s ease;
                        color: var(--yellow-theme-sub-text-color);
                        font-size: 16px;
                    }

                    .inputName:focus,
                    .inputEmail:focus,
                    .inputDescription:focus {
                        outline: none;
                    }

                    .inputName:focus+.nameLabel,
                    .inputName:valid+.nameLabel,
                    .inputEmail:focus+.emailLabel,
                    .inputEmail:valid+.emailLabel,
                    .inputDescription:focus+.descriptionLabel,
                    .inputDescription:valid+.descriptionLabel {
                        font-size: 18px;
                        top: -25px;
                        left: 0px;

                    }



                }
            }
        }



        button {
            cursor: pointer;
            // padding: 10px 70px;
        padding: 10px 30px;

            font-size: 2rem;
            background: transparent;
            color: var(--yellow-theme-main-color);
            border: 1.5px solid var(--yellow-theme-main-color);
            border-radius: 4px;
            font-weight: 500;

            &:hover {
                color: #000;
                background: var(--yellow-theme-main-color);
            }
        }


        &__connect{
        position: relative;
        display: block;
        margin: 0 auto;
        top: -500px;
        left: 300px;
        font-size: 30px;
        font-weight: 400;
        line-height: 1em;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 50%;
        // border: 2px solid rgb(4, 0, 255);
        
            h3 {
                color: #ffffff;
                letter-spacing: 0.5px;
                // border: 2px solid rgb(0, 255, 55);
                padding-bottom: 20px;
            }

            div {
                color: var(--yellow-theme-sub-text-color);
                // border: 2px solid red;
                
            }

        }
    }

    

    
}


/* for mobile */
@media screen and (max-width: 767px) {
    .contact__content {
        padding: 20px; /* Adjust padding for smaller screens */
        padding-top: 10%;
        text-align: center;

        &__header-text {
            font-size: 18px;
            padding: 5px 8px;
        }

        &__form {
            width: 100%; /* Adjust the width for better mobile responsiveness */
        }

        button {
            padding: 10px 20px; /* Adjust button padding for better mobile appearance */
        }

        &__connect {
            top: -100px; /* Adjust the positioning for better mobile appearance */
            left: 0;
            width: 80%; /* Adjust the width for better mobile responsiveness */
        }
    }

    .contact__content__form {
        &__controlswrapper {
            grid-template-columns: repeat(1, 1fr);
        }

        div {
            .nameLabel,
            .emailLabel,
            .descriptionLabel {
                font-size: 14px; /* Adjust label font size for better mobile appearance */
            }
        }
    }
}
